<template lang="pug">
    ul.tabs
        li(:class="{ active: active == tab }", @click="toggleTab(tab)", v-for="tab in tabs").tab {{ tab }}
</template>

<script>
export default {
    name: "component-tabs",
    props: {
        tabs: {
            type: Array,
            required: true
        },
        active: {
            type: String,
            required: true
        },
    },
    methods: {
        toggleTab(tab) {
            this.$emit('update:active', tab)
        },
    },
}
</script>

<style lang="stylus" scoped src="./Tabs.styl"></style>