<template lang="pug">
	article.folder
		.img
			img(v-if="!isFile" :src="cover", :alt="titulo")
			img(v-else :src="require(`@images/icons/${mediaFormat}-placeholder.png`)", :alt="titulo")
		.container
			//- a.opcoes(@click="openFolder" title="Visualizar mídia")
			router-link.opcoes(:to="{ name: 'campaign-folder', params: { campaign_id: campaign_id, folder_id: folder_id, folder_name: titulo }, query: { ...(edit && { editable: 1 }), action_id: action_id }}", title="Visualizar mídia")
				div
					img.fold(:src="require('@images/icons/folder.svg')")
				p(v-html="titulo", v-if="titulo")
</template>

<script>
export default {
	name: "campaign-folder",
	props: {
		titulo: {
			type: [String, null],
			default: ''
		},
		// downloadZips: {
		// 	type: Boolean,
		// 	default: false
		// },
		folder_id: {
			type: Number,
			default: 0
		},
		campaign_id: {
			type: Number,
			default: 0
		},
		edit: {
			type: Boolean,
			default: false
		},
		cover: {
			type: [String, null]
		},
		action_id: {
			type: [Number, null]
		}
	},
	computed: {
		isFile() {
			return this.mediaFormat == 'zip' || this.mediaFormat == 'pdf'
		},
		mediaFormat() {
			if (this.cover)
				return /\.([0-9a-z]+)$/i.exec(this.cover)[1]
			return ''
		}
	}
}
</script>

<style lang="stylus" scoped src="./Folder.styl"></style>
