<template lang="pug">
	article.media
		.img
			img(v-if="!isFile" :src="imagem.thumb", :alt="titulo")
			img(v-else :src="require(`@images/icons/${mediaFormat}-placeholder.png`)", :alt="titulo")
			.zoom(v-if="!isFile" @click="showImage(imagem.thumb)")
				include ../../assets/svgs/zoom.svg
		.container
			p(v-html="titulo", v-if="titulo")
			.opcoes
				//- button(@click="doShare()", v-if="share")
				//- 	img(:src="require('@images/icons/share.png')")
				router-link(:to="{ name: 'editor', params: {id: action_id}, query: {media_id: media_id} }", v-if="edit", title="Editar mídia")
					img.edit(:src="require('@images/icons/edit.png')")
					span Editar
				button(@click="imageZipFile ? createElement(titulo, imageZipFile) : downloadZip()" download title="Baixar zip com mais imagens", v-if="downloadZips")
					img(:src="require('@images/icons/download.png')")
					span Baixar
				a(:href="imagem.file ? imagem.file : imagem.thumb" download title="Baixar imagem", v-if="download")
					img(:src="require('@images/icons/download.png')")
					span Baixar
				router-link(:to="{ name: 'medias', params: {campaign_id: campaign ? campaign.id : null, communication_id: communication ? communication.id : null} }", v-if="more", title="Visualizar mídia")
					img.eye(:src="require('@images/icons/eye.png')")
					span Visualizar
				//- router-link(:to="{ name: 'communication-medias', params: {communication_id: communication.id}}", v-if="moreCommunication")
				//- 	img.eye(:src="require('@images/icons/eye.png')")
</template>

<script>
export default {
	name: "component-media",
	props: {
		imagem: {
			type: Object,
			required: true
		},
		titulo: {
			type: [String, null],
			default: ''
		},
		share: {
			type: Boolean,
			default: false
		},
		download: {
			type: Boolean,
			default: false
		},
		downloadZips: {
			type: Boolean,
			default: false
		},
		more: {
			type: Boolean,
			default: false
		},
		// moreCommunication: {
		// 	type: Boolean,
		// 	default: false
		// },
		edit: {
			type: Boolean,
			default: false
		},
		campaign: {
			type: Object,
			default: null
		},
		communication: {
			type: Object,
			default: null
		},
		media_id: {
			type: Number,
			default: 0
		},
		action_id: {
			type: Number,
			default: 0
		},
		imageZipFile: {
			type: String,
			default: null
		}
	},
	methods: {
		showImage(image) {
			this.$eventbus.$emit('showImage', image)
		},
		downloadZip() {
			let zipFile = ''
			if (this.campaign)
				this.$axios.get(`campaign-zip-file/${this.campaign.id}`)
					.then(response => {
						zipFile = response.data.zip_file
						this.createElement(this.campaign.name, zipFile)
					})
			if (this.communication)
				this.$axios.get(`communication-zip-file/${this.communication.id}`)
					.then(response => {
						if (response.data.zip_file)
							zipFile = response.data.zip_file
						else
							zipFile = response.data.media
						this.createElement(this.communication.display_name, zipFile)
					})
		},
		createElement(name, link) {
			let element = document.createElement("a");
			element.setAttribute('download', name);
			element.href = link;
			document.body.appendChild(element);
			element.click();
			element.remove();
		},
		doShare() {

		},
	},
	computed: {
		isFile() {
			return this.mediaFormat == 'zip' || this.mediaFormat == 'pdf'
		},
		mediaFormat() {
			if (this.imagem.thumb)
				return /\.([0-9a-z]+)$/i.exec(this.imagem.thumb)[1]
			return ''
		}
	}
}
</script>

<style lang="stylus" scoped src="./Media.styl"></style>
